import React, { useState, MouseEvent } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/system';

interface TextOnlyDropdownProps {
  options: any;
  placeholder: string;
   
}

const DropdownText = styled(Typography)({
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'transform 0.2s',
  width: '130px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  fontSize: '13px'
});

interface DropdownIconProps {
  expanded: boolean;
}

const DropdownIcon = styled(KeyboardArrowDown, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<DropdownIconProps>(({ expanded }) => ({
  transition: 'transform 0.2s',
  transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  marginRight: 'auto', // Ensure the icon is positioned to the right
}));

const TextOnlyDropdown: React.FC<TextOnlyDropdownProps> = ({ options, placeholder }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>(placeholder);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option: string | null) => {
    setAnchorEl(null);
    if (option) {
      setSelectedOption(option);
    }
  };

  return (
    <div className="dropdown-container">
      <DropdownText     
       onClick={handleClick}>
        {selectedOption}
        <DropdownIcon expanded={Boolean(anchorEl)} />
      </DropdownText>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        PaperProps={{
          style: {
            minWidth: '150px',
          },
        }}
      >
        {options.map((option: any ) => (
          <MenuItem selected   key={option} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default TextOnlyDropdown;
