import React from 'react';

const Footer = () => {
    return (
        <div style={{
            backgroundColor: '#2a358c',
            color: 'white',
            textAlign: 'center',
            padding: '10px 0 1px 0',
            left: 0,
            bottom: 0,
            width: '100%',
            fontSize: '13px',
            fontWeight: 500,
        }}>
            <p>Copyright &copy; 2024 Infinity Global Distribution. All Rights Reserved.</p>
        </div>
    );
}

export default Footer;
