import React, { useEffect } from 'react';
import { DataGridPremium, GridRowsProp, GridToolbar } from '@mui/x-data-grid-premium';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';






export default function DataGridOrders() {

  const [status, setStatus] = React.useState('All');
  const [statusData, setStatusData] = React.useState([])
  const navigate = useNavigate();
  const [orderData, setOrderData] = React.useState([])

  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlOrder: any = "https://igddev.igdistribution.net/igdcpapi/api/user/getDashData_orders"
  const apiUrlStatus: any = "https://igddev.igdistribution.net/igdcpapi/api/user/getDashCounters?fromDate=2024-01-01&toDate=2024-03-03"
  

  async function getDashData_status() {
    axios.get((apiUrlStatus), {
      headers: {
        Authorization: `Bearer ${tokenValue}`
      }
    })
      .then(response => {
       
        setStatusData(response.data.Payload)
        if(status)
                {
          getOrdersApiData('All')
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  async function getOrdersApiData(params: any) {
    const responseOrders = await getDashData_orders(
      {
        "orderStatus": params,
        "fromDate": "2024-01-01",
        "toDate": "2024-03-03"
      })
    if (responseOrders) {       
      setOrderData(responseOrders.Payload)
    }
  }

  async function getDashData_orders(params: any) {
    return fetch(apiUrlOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
 navigate("/login");
 window.location.reload();
      })
  }

  useEffect(() => {
    try { 
      getDashData_status()
    }
    catch (e) {      
      //log error
    }
  },
    []);

  const handleChange = async (event: SelectChangeEvent) => {
    
    setStatus(event.target.value);
    if (event.target.value) {
      const statusValue = event.target.value
      getOrdersApiData(statusValue)
    }
  };

  const columns = [
   
    { field: 'OrderNumber', headerName: 'Order Number', width: 150 },
    { field: 'Owner', headerName: 'Owner', width: 150 },
    { field: 'OrderDate', headerName: 'OrderDate', width: 150 },
    { field: 'orderStatus', headerName: 'orderStatus', width: 150 },
    { field: 'CarrierName', headerName: 'CarrierName', width: 200 },
    { field: 'ExternalOrderNo', headerName: 'External OrderNo', width: 300 },
    { field: 'ShipTo', headerName: 'Ship To', width: 100 },
   
  ]

  const rows: GridRowsProp = orderData?.map((c:any) => ({   
    OrderNumber: c.OrderNumber,
    Owner: c.Owner,
    OrderDate:c.OrderDate,
    orderStatus:c.orderStatus,
    CarrierName:c.CarrierName,
    ExternalOrderNo:c.ExternalOrderNo,
    ShipTo : c.ShipTo
  }));


  return (

    <div className='mt-8 p-4'  style={{ height: 500, width: '100%' }}>
       {/* <p className='ml-2  mb-8'>Order status :</p>  */}
           <div className=''>
      {statusData?.map((s: any) => {
            return (
              <div className='containerDiv colordiv'>
             {s.orderStatus}{" - "}{s.cnt}
              </div>
            )
      })}
      </div>
     
      <div className='pb-2 mt-16' style={{ width: '20%' }}>
    <p className='mb-2'> Filter by status : </p>
        <Select
          size='small'
          fullWidth
          className='mr-4'
          value={status}
          onChange={handleChange}
          defaultValue={status}
          inputProps={{ 'aria-label': 'Without label' }}
        >
           <MenuItem value="All">All</MenuItem>
          {statusData.map((s: any) => {
            return (
              // {s.orderStatus} - {s.cnt}
              <MenuItem  key={s.cnt} value={s.orderStatus}>{s.orderStatus} </MenuItem>
            )            
          })}

        </Select>
      
      </div>  
      <p className='ml-2 mt-2 mb-2'>Shipment Order Details :</p>    
              <DataGridPremium
              getRowId={(row : any) => row?.OrderNumber}
              columns={columns}
              rows={rows}
              ></DataGridPremium>
           
    </div>
  );
}
