import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@mui/material/CircularProgress";

import { Link, Navigate, useNavigate } from "react-router-dom";

const apiUrlPermissionUser: any = "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserNavMenus"

const urlSaveUserCurrStateSettings =
    "https://igddev.igdistribution.net/igdcpapi/api/User/SaveUserCurrStateSettings";

const urlGetUserCurrStateSettings =
    "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserCurrStateSettings";


const urlPgMasterData =
    "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserMasterPgData";


const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        // marginLeft:"15%",
        alignItems: "center",
        paddingLeft: "350px",

    },
    image: {
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
    },
    paper: {
        margin: theme.spacing(8, 4),

        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


async function loginUser(credentials: any) {
    return fetch("https://igddev.igdistribution.net/igdcpapi/api/account/Login", {

        method: "POST",
        headers: {
            'Content-Type': 'application/json;'
        },
        body: JSON.stringify(credentials),
    }).then((data) => data.json())
        .catch((error) => {
            console.log("Failed")

        })
}

export default function Signin() {
    const classes = useStyles();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loginMsg, setloginMsg] = useState("");
    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(false);


    async function saveCurrentSettings(credentials: any, tokenValue: any) {
        return fetch(urlSaveUserCurrStateSettings, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;",
                Authorization: `Bearer ${tokenValue}`,
            },
            body: JSON.stringify(credentials),
        })
            .then((data) => data.json())
            .catch((error) => {
                console.log("Failed");
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsFetching(true);
        const response = await loginUser({
            username,
            password,
        });

        if (response?.Payload) {
            console.log("set login localstorage")
            localStorage.setItem("userId", response.Payload["Id"]);
            localStorage.setItem("accessToken", response.Payload["token"]);
            localStorage.setItem("fname", response.Payload["FirstName"]);
            localStorage.setItem("lname", response.Payload["LastName"]);
            localStorage.setItem("user", JSON.stringify({ role: "ADMIN" }))
            //call master page Owner and whs
            //localStorage.clear()
            //call permissions api

            getPermissionsByUser(response.Payload["Id"], response.Payload["token"])



            //get current settings
            const userSettingsResponse = await getCurrentSettings(
                { userId: response.Payload["Id"] },
                response.Payload["token"]
            );

            if (
                userSettingsResponse?.Payload &&
                userSettingsResponse.Payload.length > 0
            ) {
                const userSettings = userSettingsResponse.Payload[0];

                localStorage.setItem("currentOwner", userSettings.currCustomer);
                localStorage.setItem("currentWHS", userSettings.currWarehouse);

            }
            else {
                const responseUserPerm = await getPageMasterData(
                    {
                        userId: response.Payload["Id"],
                    },
                    response.Payload["token"]
                );

                if (responseUserPerm?.Payload) {
                    //save 

                    const responseUserSave = await saveCurrentSettings(
                        {
                            userId: response.Payload["Id"],
                            currCustomer: responseUserPerm?.Payload?.Table[0].custID,
                            currWarehouse: responseUserPerm?.Payload?.Table1[0].whseID,
                        },
                        response.Payload["token"]
                    );
                    if (responseUserSave?.Payload) {
                        console.log("saved default settings")
                    }

                    localStorage.setItem("currentOwnerName", responseUserPerm?.Payload?.Table[0].customerName);
                    localStorage.setItem("currentOwner", responseUserPerm?.Payload?.Table[0].custID);

                    localStorage.setItem("currentWHS", responseUserPerm?.Payload?.Table1[0].whseID);
                    localStorage.setItem("currentWHSName", responseUserPerm?.Payload?.Table1[0].warehouseName);

                }
            }

            setloginMsg("Logged in successfully")

            setIsFetching(false);
            navigate('/dashboard');


        }
        else {
            setIsFetching(false);
            setloginMsg(response?.Message)
        }

    };

    async function getPageMasterData(credentials: any, tokenValue: any) {
        return fetch(urlPgMasterData, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;",
                Authorization: `Bearer ${tokenValue}`,
            },
            body: JSON.stringify(credentials),
        })
            .then((data) => data.json())
            .catch((error) => {
                console.log("Failed");
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            });
    }

    async function getPermissionsUser(credentials: any, tokenValue: any) {
        return fetch(apiUrlPermissionUser, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                // localStorage.clear();
                //navigate("/login");
                //  window.location.reload();
            })
    }

    async function getCurrentSettings(credentials: any, tokenValue: any) {
        return fetch(urlGetUserCurrStateSettings, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                // localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    async function getPermissionsByUser(param: any, tokenValue: any) {
        const responseUserPerm = await getPermissionsUser(
            {
                "userId": param
            }, tokenValue
        )

        if (responseUserPerm?.Payload) {
            console.log("sign in set permissions", localStorage)
            /////setuserPermissionMenu(responseUserPerm?.Payload)
            const dashboardPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName == "Dashboard")
            ) !== undefined

            localStorage.setItem("isDashboard", dashboardPermission.toString());

            const orderPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName.includes("ORDERS"))
            ) !== undefined

            localStorage.setItem("isOrderTab", orderPermission.toString());

            const asnPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName === "ASN")
            ) !== undefined

            localStorage.setItem("isAsnTab", asnPermission.toString());

            const inventoryPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName == "Inventory")
            ) !== undefined

            localStorage.setItem("isInventoryTab", inventoryPermission.toString());

            const repackPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName.includes("Repack"))
            ) !== undefined

            localStorage.setItem("isRepackTab", repackPermission.toString());

            const returnPermission = responseUserPerm?.Payload.find((obj: any) =>
                responseUserPerm?.Payload.some((charList: any) => charList.menuName.includes("Return"))
            ) !== undefined

            localStorage.setItem("isReturnTab", returnPermission.toString());

            // window.location.reload();
            console.log("sign in after settting  permissions", localStorage)

            //check current permissions

          //  navigate("/dashboard")
         // navigate("/topnav")
         //window.location.reload()
         navigate("/dashboard")
        }
    }


    useEffect(() => {
        console.log("clear storage", localStorage)
        localStorage.clear()
    })

    return (
        <Grid className='signin pt-10'>
            {isFetching ? (
                <CircularProgress></CircularProgress>
            ) : (
                <><CssBaseline /> <Grid className="" item xs={8} md={4} component={Paper} elevation={6} square>

                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)} />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                        </form>
                        <div>
                            <p className="loginMsg">{loginMsg}</p>
                        </div>
                    </div>
                </Grid></>
            )}
        </Grid>
    );
}