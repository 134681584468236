import React, { Component, useEffect, useState } from 'react'
import "./createUser.css"
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, alpha, styled } from '@mui/material';
import { Table } from 'react-bootstrap';
import CustomStyling from './PermissionList';
import ControlledTreeView from './newtree';
import { RichTreeView } from '@mui/x-tree-view';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';
import RecursiveTreeView from './newtrees';
import { useNavigate } from "react-router-dom";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Permissions() {

  const [userPermissionCustomers, setuserPermissionCustomers] = React.useState([])
  const [userPermissionMenu, setuserPermissionMenu] = React.useState([])
  const [userPermission, setuserPermission] = React.useState([])
  const [userLocations, setuserLocations] = React.useState([])
  const [usersList, setusersList] = React.useState([])
  const [menuList, setmenuList] = React.useState([])
  const navigate = useNavigate();

  const [selectedCustIds, setselectedCust] = React.useState([])
  const [selectedlocids, setselectedlocids] = React.useState([])

  const [userId, setUserId] = React.useState('');
  const tokenValue: any = localStorage.getItem("accessToken")
  const currentUserID: any = localStorage.getItem("userId")

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [locationName, setlocationName] = React.useState<string[]>([]);

  console.log("permission pagecurrentUserID", currentUserID)

  const handleChange = (event: SelectChangeEvent<string[]>) => {

    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleLocChange = (event: SelectChangeEvent<string[]>) => {

    const {
      target: { value },
    } = event;
    setlocationName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleCheckChange = (event: SelectChangeEvent<string[]>) => {

    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  const handleCheckChangeLoc = (event: SelectChangeEvent<string[]>) => {

    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };



  const apiUrlPermissionData: any = "https://igddev.igdistribution.net/igdcpapi/api/Admin/GetUserPermissionsPgData"
  const apiUrlPermissionList: any = "https://igddev.igdistribution.net/igdcpapi/api/Admin/GetMenuList"
  const apiUrlSavePermission: any = "https://igddev.igdistribution.net/igdcpapi/api/Admin/SaveUserPermissions"

  const apiUrlPermissionUser: any = "https://igddev.igdistribution.net/igdcpapi/api/Admin/GetUserPermissions"

  const [lastSelectedItem, setLastSelectedItem] = React.useState<string | null>(
    null,
  );

  const [userMsg, setUserMsg] = React.useState("")

  const [msgCss, setmsgCss] = React.useState("")

  let permissionCustomers: any = []
  let permissionLocations: any = []

  const [selectedItems, setselectedItems] = React.useState<string[]>([]);

  async function getPermissionsData(param: any) {
    const responseUserPerm = await getPermissions(param
    )
    if (responseUserPerm) {

      setuserPermission(responseUserPerm?.Payload.Table1)
      permissionCustomers = responseUserPerm?.Payload.Table1

      setuserLocations(responseUserPerm?.Payload.Table2)
      permissionLocations = responseUserPerm?.Payload.Table2

      setusersList(responseUserPerm?.Payload.Table)
      //  console.log("responseUserPerm", responseUserPerm)

    }
  }

  async function getPermissions(credentials: any) {
    return fetch(apiUrlPermissionData, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function getPermissionsUser(credentials: any) {
    return fetch(apiUrlPermissionUser, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }


  async function getPermissionsByUser(param: any) {
    const responseUserPerm = await getPermissionsUser(
      {
        "userId": param
      }
    )
    if (responseUserPerm?.Payload) {
      setuserPermissionMenu(responseUserPerm?.Payload.Table1)
      setuserPermissionCustomers(responseUserPerm?.Payload.Table)

      //set customers amd menu selected

      let selectedCust: any = []
      responseUserPerm?.Payload.Table?.map((cust: any) => {
        selectedCust.push(cust.custID)
      })
      setselectedCust(selectedCust)

      //set locations
      let selectedloc: any = []
      responseUserPerm?.Payload.Table2?.map((loc: any) => {
        selectedloc.push(loc.whseID)
      })
      setselectedlocids(selectedloc)

      //set menu
      let selectedMenu: any = []
      responseUserPerm?.Payload.Table1?.map((menu: any) => {
        selectedMenu.push(menu.menuID)
        setLastSelectedItem(menu.menuID)
      })

      //get cust name 
      let custArray: any = []
      if (permissionCustomers?.length > 0) {
        selectedCust.forEach((itemP: any) => {
          permissionCustomers.forEach((itemUP: any) => {
            if (itemP == itemUP.custID) {
              custArray.push(itemUP.customerName)
            }
          })
        })
        setPersonName(custArray)
      }
      else {
        selectedCust.forEach((itemP: any) => {
          userPermission.forEach((itemUP: any) => {
            if (itemP == itemUP.custID) {
              custArray.push(itemUP.customerName)
            }
          })
        })
        setPersonName(custArray)
      }

      //get locations  
      let locationsArray: any = []
      if (permissionLocations?.length > 0) {
        selectedloc.forEach((itemP: any) => {
          permissionLocations.forEach((itemUP: any) => {
            if (itemP == itemUP.whseID) {
              locationsArray.push(itemUP.whseName)
            }
          })
        })
        setlocationName(locationsArray)
      }
      else {
        selectedloc.forEach((itemP: any) => {
          userLocations.forEach((itemUP: any) => {
            if (itemP == itemUP.whseID) {
              locationsArray.push(itemUP.whseName)
            }
          })
        })
        setlocationName(locationsArray)
      }

      //set menu permissions
      setselectedItems(selectedMenu)

    }
  }



  const handleUserChange = async (event: SelectChangeEvent) => {
    setUserId(event.target.value);
    if (event.target.value) {
      //call permissions api to get user assigned permissions
      getPermissionsByUser(event.target.value)
    }
  }

  const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],

    [`& .${treeItemClasses.content}`]: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      [`& .${treeItemClasses.label}`]: {
        fontSize: '1.2rem',
        fontWeight: 500,
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.main, 0.25)
          : theme.palette.primary.dark,
      color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1.2),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  useEffect(() => {
    try {
      getPermissionsData({})
      getPermissionsListData({})
      setUserId(currentUserID)
      getPermissionsByUser(currentUserID)
      // setPersonName(selectedCustIds)
    }
    catch (e) {
      //log error
    }
  },
    []);

  const submitData = (e: any) => {
    e.preventDefault();

    let custArray: any = []
    personName.forEach((itemP: any) => {
      userPermission.forEach((itemUP: any) => {
        if (itemP == itemUP.customerName) {
          custArray.push(itemUP.custID)
        }
      })
    })

    let locaArray: any = []
    locationName.forEach((itemP: any) => {
      userLocations.forEach((itemUP: any) => {
        if (itemP == itemUP.whseName) {
          locaArray.push(itemUP.whseID)
        }
      })
    })

    const createUserResponse = updateUserData(
      {
        "userId": userId,
        "custIDs": custArray.toString(),
        "menuIDs": selectedItems.toString(),
        "whseIDs": locaArray.toString()
      }
    )
    if (createUserResponse) {
      console.log("createUserResponse", createUserResponse)
      //  window.location.reload();
      //navigate('/dashboard');
    }

    //navigate('/dashboard');
  }


  async function getPermissionsListData(param: any) {
    const responseUserPerm = await getPermissionsList(param
    )

    if (responseUserPerm) {
      setmenuList(responseUserPerm?.Payload)

    }
  }

  async function getPermissionsList(credentials: any) {
    return fetch(apiUrlPermissionList, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function updateUserPermissions(credentials: any) {

    return fetch(apiUrlSavePermission, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function updateUserData(param: any) {

    const responseUserCreate = await updateUserPermissions(param
    )

    if (responseUserCreate?.Payload) {

      if (responseUserCreate?.Payload?.Table[0].status == 0) {
        setUserMsg(responseUserCreate?.Payload.Table[0].message)
        setmsgCss("loginMsg")
      }
      else {
        // setcreateuserData(responseUserCreate)
        // if (responseUserCreate?.Status == 0) {
        //     //set error msg
        setUserMsg(responseUserCreate?.Payload.Table[0].message)
        setmsgCss("loginSuccMsg")
        if (responseUserCreate?.Payload.Table[0].message != "") {
          alert("Refreshing Dashboard")
          console.log("responseUserCreate?.Message",responseUserCreate?.Message)
          window.location.reload()
        }
        // }
        // else {
        //     setUserMsg(responseUserCreate.Message)
        //     setmsgCss("loginSuccMsg")
        //     getuserData({})
        // }
      }

    }
    else {
      setUserMsg(responseUserCreate?.Message)
      setmsgCss("loginMsg")
      
    }

  }

  const handleItemSelectionItems = (
    event: React.SyntheticEvent, itemIds: string[]
  ) => {

    setselectedItems(itemIds)
    console.log("itemIds", itemIds)
  }


  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,

    itemId: string,
    isSelected: boolean,

  ) => {


    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };

  return (

    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-lg-9 col-xl-7 mt-8">
            <div className='mb-8  text-center pageHeading'>App Settings - User</div>
            <div className="card shadow-2-strong card-registration" style={{ borderRadius: 15 }}>

              <>
                <div className="card-body p-4 p-md-5">
                  <h5 className="mb-4 font-bold  pb-md-0 mb-md-5"></h5>
                  <form >
                    <div className="row ">
                      <div className="col-md-4 mb-2">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="firstName">Select User</label>
                          <Select
                            size='small'
                            className=" form-control"
                            value={userId}
                            onChange={handleUserChange}
                            defaultValue={userId}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {/* <MenuItem value="Select User">Select User </MenuItem> */}
                            {usersList.map((s: any) => {
                              return (
                                <MenuItem defaultChecked key={s.userId} value={s.userId}>{s.UserName} </MenuItem>
                              )
                            })}

                          </Select>
                        </div>

                      </div>
                      <div className="col-md-4 mb-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="lastName">Select Customer</label>
                          <Select
                            size='medium'
                            className=" form-control"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {userPermission.map((s: any) => (
                              <MenuItem key={s.custID} value={s.customerName}>
                                <Checkbox onChange={handleCheckChange} checked={personName.indexOf(s.customerName) > -1} />
                                <ListItemText primary={s.customerName} />
                              </MenuItem>
                            ))}
                          </Select>

                        </div>

                      </div>

                      <div className="col-md-4 mb-2">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="firstName">Select Location</label>
                          <Select
                            size='medium'
                            className=" form-control"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={locationName}
                            onChange={handleLocChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {userLocations.map((s: any) => (
                              <MenuItem key={s.whseID} value={s.whseName}>
                                <Checkbox onChange={handleCheckChangeLoc} checked={locationName.indexOf(s.whseName) > -1} />
                                <ListItemText primary={s.whseName} />
                              </MenuItem>
                            ))}
                          </Select>

                        </div>

                      </div>
                    </div>



                  </form>
                </div></>


            </div>
          </div>
        </div>

        <div>
          <h5 className='font-bold'>Permissions List</h5>
          <RichTreeView
            checkboxSelection={true}
            multiSelect={true}
            selectedItems={selectedItems}
            defaultSelectedItems={['1']}
            defaultExpandedItems={['1', '2']}
            sx={{ overflowX: 'visible', minHeight: 220, flexGrow: 1, maxWidth: 300 }}
            slots={{ item: StyledTreeItem }}
            items={menuList}
            onSelectedItemsChange={handleItemSelectionItems}
            onItemSelectionToggle={handleItemSelectionToggle}
          />


          <div className="mt-2">
            <input className="btn btn-primary ml-2" type="button" onClick={submitData} value="Submit" />
            <p className={msgCss}>{userMsg}</p>
          </div>
        </div>

      </div>



    </section>

  )
}