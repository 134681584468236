import React, { MouseEvent, useEffect, useState } from "react";
import "./index.css";
import logoigt from "../../assets/logoigt.png";
import { Avatar, Button, Menu, MenuItem, SelectChangeEvent } from "@mui/material";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextOnlyDropdown from "./TextOnlyDropdown";
import { Link, useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

const Topnav: React.FC = () => {
  const [reportsOption, setReportsOption] = useState<null | HTMLElement>(null);
  const [adminOption, setAdminOption] = useState<null | HTMLElement>(null);
  const [avatarOption, setAvatarOption] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState<number | null>(null);
  const [logintext, setLoginText] = useState("Login");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [headerClass, setHeaderClass] = useState("top-nav-hide");
  const [userPermissionMenu, setuserPermissionMenu] = useState<any[]>([]);
  const [userMasterData, setuserMasterData] = React.useState([]);
  const [userCurrentSettings, setuserCurrentSettings] = React.useState([]);
  const [newUserOwner, setnewUserOwner] = React.useState("");
  const [newUserWHS, setnewUserWHS] = React.useState("");
  const [userWhsMasterData, setuserWhsMasterData] = React.useState([]);

  const [inboundOption, setInboundOption] = useState<null | HTMLElement>(null);
  const [outboundOption, setOutboundOption] = useState<null | HTMLElement>(
    null
  );
  const [inventoryOption, setInventoryOption] = useState<null | HTMLElement>(
    null
  );
  const [billingOption, setBillingOption] = useState<null | HTMLElement>(null);
  const [containerOption, setContainerOption] = useState<null | HTMLElement>(
    null
  );

  const [selectedUserID, setselectedUserID] = React.useState(localStorage.getItem("currentOwner") ?? "")
  const [selectedWhsID, setselectedWhsID] = React.useState(localStorage.getItem("currentWHS") ?? "")

  const [selectedWhsName, setselectedWhsName] = React.useState(localStorage.getItem("currentWHSName") ?? "")
  const [selectedOwnerName, setnewUserOwnerName] = React.useState(localStorage.getItem("currentOwnerName") ?? "")


  const navigate = useNavigate();
  let owName : any = localStorage.getItem("currentOwnerName")
  let whsName : any = localStorage.getItem("currentWHSName")
  const firstname = localStorage.getItem("fname") || "";
  const lastname = localStorage.getItem("lname") || "";
  // const currentUserID = localStorage.getItem("userId") || "";
  const currentUserID: any = localStorage.getItem("userId")
  const tokenValue = localStorage.getItem("accessToken") || "";


  const urlGetUserCurrStateSettings =
    "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserCurrStateSettings";

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(event.currentTarget.offsetWidth);
  };

  const handleCloseMenu = (
    setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    setParentAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>,
    setSubmenuAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setParentAnchorEl(null);
    setSubmenuAnchorEl(null);
  };

  const logout = () => {
    setHeaderClass("top-nav-hide");
    localStorage.clear();
    setLoginText("Login");
    navigate("/");
    window.location.reload()

  };

  async function getCurrentSettings(credentials: any, tokenValue: any) {
    return fetch(urlGetUserCurrStateSettings, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function getPermissionsUser(credentials: any, tokenValue: any) {
    return fetch(apiUrlPermissionUser, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }


  const fetchUserPermissionsAndSettings = async () => {

    const userPermissionsResponse = await getPermissionsUser(
      { userId: currentUserID },
      tokenValue
    );
    if (userPermissionsResponse?.Payload) {
      setuserPermissionMenu(userPermissionsResponse.Payload);
    }

    const userSettingsResponse = await getCurrentSettings(
      { userId: currentUserID },
      tokenValue
    );

    if (
      userSettingsResponse?.Payload &&
      userSettingsResponse.Payload.length > 0
    ) {
      const userSettings = userSettingsResponse.Payload[0];
      localStorage.setItem("currentOwner", userSettings.currCustomer);
      localStorage.setItem("currentWHS", userSettings.currWarehouse);

      //set name 
      
      if (userSettings.currCustomer === "DSTVAL") {
        localStorage.setItem("currentOwnerName", "DISTRIVALTO");
        setnewUserOwnerName("DISTRIVALTO")

      }
      else if (userSettings.currCustomer === "WALMRT") {
        localStorage.setItem("currentOwnerName", "Wallmart");
        setnewUserOwnerName("Wallmart")

      }
      else if (userSettings.currCustomer === "FLPKRT") {
        localStorage.setItem("currentOwnerName", "Flipkart");
        setnewUserOwnerName("Flipkart")

      }
      //whs
      localStorage.setItem("currentWHSName", userSettings.currWarehouse);
      setselectedWhsName(userSettings.currWarehouse)
    }
    else {
      //set default values
      console.log("else selectedCustomer", newUserOwner)
      console.log("else selectedWHS", newUserWHS)
      const dashboardTab = localStorage.getItem("isDashboard")
      if (dashboardTab == "true") {

        console.log("dashboard true on topnav page")

      }
    }
  }
  useEffect(() => {
    console.log("eff4", currentUserID)
    if (currentUserID && tokenValue) {

      console.log("useEffect4")
     

      fetchUserPermissionsAndSettings();
    }
  }, []);

  useEffect(() => {
    console.log("useEffect5")
    setFname(firstname);
    setLname(lastname);
    if (firstname) {
      setHeaderClass("");
      setLoginText("Logout");
    } else {
      setHeaderClass("top-nav-hide");
      setLoginText("Login");
    }
  }, [firstname, lastname]);

  async function saveCurrentSettingsByUser(
    param: any,
    tokenValue: any,
    cust: any,
    whs: any
  ) {
    const responseUser = await saveCurrentSettings(
      {
        userId: currentUserID,
        currCustomer: cust,
        currWarehouse: whs,
      },
      tokenValue
    );
    if (responseUser?.Payload) {

      setselectedUserID(cust);
      setselectedWhsID(whs);
      // setnewUserOwnerName()
      window.location.reload();
      //navigate("/dashboard")
    }
  }

  const urlSaveUserCurrStateSettings =
    "https://igddev.igdistribution.net/igdcpapi/api/User/SaveUserCurrStateSettings";
  const apiUrlPermissionUser: any =
    "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserNavMenus";
  const urlPgMasterData =
    "https://igddev.igdistribution.net/igdcpapi/api/User/GetUserMasterPgData";

  async function saveCurrentSettings(credentials: any, tokenValue: any) {
    return fetch(urlSaveUserCurrStateSettings, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }


  //handler
  const handleUserChange = async (item: any, name: any) => {

    setnewUserOwnerName(name)
    setselectedUserID(item);
    if (item) {
      localStorage.setItem("currentOwner", item);
      localStorage.setItem("currentOwnerName", name);

      //save data
      saveCurrentSettingsByUser("", tokenValue, item, selectedWhsID)
    }
  }

  const handleWhsChange = async (item: any, name: any) => {
    setselectedWhsName(name)
    setselectedWhsID(item);
    if (item) {
      localStorage.setItem("currentWHS", item);
      localStorage.setItem("currentWHSName", name);
      saveCurrentSettingsByUser("", tokenValue, selectedUserID, item)
    }
  }

  async function getPermissionsByUser(param: any, tokenValue: any) {
    const responseUserPerm = await getPermissionsUser(
      {
        userId: param,
      },
      tokenValue
    );

    if (responseUserPerm?.Payload) {
      setuserPermissionMenu(responseUserPerm?.Payload);
    }
  }

  async function getCurrentSettingsByUser(param: any, tokenValue: any) {
    const responseUserSettings = await getCurrentSettings(
      {
        "userId": currentUserID
      }, tokenValue
    )

    if (responseUserSettings?.Payload) {
      if (responseUserSettings?.Payload?.length > 0) {
        
        console.log("setuserCurrentSettings")
        setuserCurrentSettings(responseUserSettings?.Payload)
        setselectedUserID(responseUserSettings?.Payload[0].currCustomer)
        localStorage.setItem("currentOwner", responseUserSettings?.Payload[0].currCustomer);
        setselectedWhsID(responseUserSettings?.Payload[0].currWarehouse)
        localStorage.setItem("currentWHS", responseUserSettings?.Payload[0].currWarehouse);

        //set name 
        
        if (responseUserSettings?.Payload[0].currCustomer === "DSTVAL") {
          localStorage.setItem("currentOwnerName", "DISTRIVALTO");
          setnewUserOwnerName("DISTRIVALTO")

        }
        else if (responseUserSettings?.Payload[0].currCustomer === "WALMRT") {
          localStorage.setItem("currentOwnerName", "Wallmart");
          setnewUserOwnerName("Wallmart")

        }
        else if (responseUserSettings?.Payload[0].currCustomer === "FLPKRT") {
          localStorage.setItem("currentOwnerName", "Flipkart");
          setnewUserOwnerName("Flipkart")

        }
        //whs
        localStorage.setItem("currentWHSName", responseUserSettings?.Payload[0].currWarehouse);
        setselectedWhsName(responseUserSettings?.Payload[0].currWarehouse)

        getPageMasterDataByUser(currentUserID, tokenValue, true)

      }
      else {

        //set default values
        console.log("2 else selectedCustomer", newUserOwner)
        console.log("2 else selectedWHS", newUserWHS)

        getPageMasterDataByUser(currentUserID, tokenValue, false)

        const dashboardTab = localStorage.getItem("isDashboard")
        if (dashboardTab == "true") {
          console.log("346", dashboardTab)
        }
      }
    }
  }

  //master data ddl
  async function getPageMasterData(credentials: any, tokenValue: any) {
    return fetch(urlPgMasterData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function getPageMasterDataByUser(param: any, tokenValue: any, isCurrentSettings: boolean) {
    console.log("top nav")
    const responseUserPerm = await getPageMasterData(
      {
        userId: currentUserID,
      },
      tokenValue
    );
    
    if (responseUserPerm?.Payload) {
      console.log("set master data", responseUserPerm?.Payload)
      console.log("isCurrentSettings", isCurrentSettings)
      
      setuserMasterData(responseUserPerm?.Payload.Table);
      setnewUserOwner(responseUserPerm?.Payload?.Table[0].custID);
      setuserWhsMasterData(responseUserPerm?.Payload.Table1);
      setnewUserWHS(responseUserPerm?.Payload?.Table1[0].whseID);


      const ownerWHS: any = localStorage.getItem("currentWHSName")
      const ownerCust: any = localStorage.getItem("currentOwnerName")
      setselectedWhsName(ownerWHS)
      setnewUserOwnerName(ownerCust)

      if (isCurrentSettings && userCurrentSettings?.length == 0) {
        console.log("blanck settings",localStorage)
        localStorage.setItem("isDashboard", "true")
        //setselectedWhsName(ownerWHS)
        owName = localStorage.getItem("currentOwnerName")
        console.log("owName",owName)
setnewUserOwnerName(owName)
        whsName =localStorage.getItem("currentWHSName")

        setselectedWhsName(whsName)
        console.log("owName",owName)
        //setuserCurrentSettings([])
        //window.location.reload()
      // navigate("/dashboard")
      // window.location.reload()
      }
    }
  }

  useEffect(() => {
    console.log("useeffect1 check currentUserID", currentUserID)

    if (currentUserID) {
      console.log("useEffect1 if")
      getCurrentSettingsByUser(currentUserID, tokenValue);
      getPermissionsByUser(currentUserID, tokenValue);

      //  window.location.reload();
    }
  }, [currentUserID]);

  useEffect(() => {
    console.log("setuserMasterData", userMasterData);
  }, [setuserMasterData]);

  // useEffect(() => {
  //   console.log("useEffect2 with selectedWhsID", localStorage)
  //   if (currentUserID) {
  //     const isLoaded = localStorage.getItem("loadTab")

  //     if (isLoaded != "true") {
  //       localStorage.setItem("loadTab", "true");
       
  //     }
  //   }

  // }, []);

  useEffect(() => {
    console.log("useEffect2")   
    if (currentUserID) {
    const isLoaded = localStorage.getItem("loadTab")
    
    if (isLoaded != "true") {
      localStorage.setItem("loadTab", "true");
     // window.location.reload();
    }
  }
    

  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 450);

  useEffect(() => {
    console.log("useEffect3")
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 450);
    };

    window.addEventListener("resize", handleResize);
    //window.location.reload()
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className="App-header">
      <img
        src={logoigt}
        style={{
          filter: "brightness(0) invert(1)",
          objectFit: "contain",
          width: isSmallScreen ? "30vw" : "9vw",
          height: "100%",
          marginLeft: "8px",
        }}
        alt="logo"
      />
      {currentUserID && (
        <div className="button-grid">
          {
            (userPermissionMenu?.find(() =>
              userPermissionMenu?.some((charList: any) => charList.menuName.includes("Dashboard"))
            ) !== undefined) &&
            <Button className="hover-effect" component={Link} to="/dashboard">
              <DataUsageIcon /> &nbsp; Dashboard
            </Button>
          }

          {
            (userPermissionMenu?.find((obj: any) =>
              userPermissionMenu?.some((charList: any) => charList.menuName.includes("Reports"))
            ) !== undefined) &&
            <Button
              className="hover-effect"
              onClick={(e) => handleOpenMenu(e, setReportsOption)}
            >
              <AssessmentIcon /> &nbsp; Reports
            </Button>}

          <Menu
            anchorEl={reportsOption}
            open={Boolean(reportsOption)}
            onClose={() => {
              handleCloseMenu(setReportsOption);
              handleCloseMenu(setInboundOption);
              handleCloseMenu(setOutboundOption);
              handleCloseMenu(setInventoryOption);
              handleCloseMenu(setBillingOption);
              handleCloseMenu(setContainerOption);
            }}

          >

            <div>
              {
                (userPermissionMenu?.find((obj: any) =>
                  userPermissionMenu?.some((charList: any) => charList.menuName.includes("Inbound"))
                ) !== undefined) &&
                <MenuItem onClick={(e) => handleOpenMenu(e, setInboundOption)}>
                  <Link to="#">Inbound</Link>
                </MenuItem>}


              <Menu
                anchorEl={inboundOption}
                open={Boolean(inboundOption)}
                onClose={() => handleCloseMenu(setInboundOption)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: "200px" || "auto",
                  },
                }}
              >
                {
                  (userPermissionMenu?.find((obj: any) =>
                    userPermissionMenu?.some((charList: any) => charList.menuName.includes("ASN Receipts"))
                  ) !== undefined) &&
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(setReportsOption, setInboundOption)
                    }
                  >
                    <Link to="/ansRec">ASN Receipts</Link>
                  </MenuItem>}

              </Menu>
            </div>


            {
              (userPermissionMenu?.find((obj: any) =>
                userPermissionMenu?.some((charList: any) => charList.menuName.includes("Outbound"))
              ) !== undefined) && (
                <div>
                  <MenuItem onClick={(e) => handleOpenMenu(e, setOutboundOption)}>
                    <Link to="#">Outbound</Link>
                  </MenuItem>
                  <Menu
                    anchorEl={outboundOption}
                    open={Boolean(outboundOption)}
                    onClose={() => handleCloseMenu(setOutboundOption)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "200px" || "auto",
                      },
                    }}
                  >
                    {
                      (userPermissionMenu?.find((obj: any) =>
                        userPermissionMenu?.some((charList: any) => charList.menuName.includes("Shipment Datewise"))
                      ) !== undefined) && (
                        <MenuItem
                          onClick={() =>
                            handleMenuItemClick(setReportsOption, setOutboundOption)
                          }
                        >
                          <Link to="/reportOrderDate">Shipment Report Date wise</Link>
                        </MenuItem>
                      )}
                    {
                      (userPermissionMenu?.find((obj: any) =>
                        userPermissionMenu?.some((charList: any) => charList.menuName.includes("Shipment Carrierwise"))
                      ) !== undefined) && (
                        <MenuItem
                          onClick={() =>
                            handleMenuItemClick(setReportsOption, setOutboundOption)
                          }
                        >
                          <Link to="/reportOrderCarrier">
                            Shipment Report Carrier wise
                          </Link>
                        </MenuItem>
                      )}
                  </Menu>
                </div>)}

            {userPermissionMenu.some((menu) =>
              menu.menuName.includes("Inventory")
            ) && (
                <div>
                  <MenuItem onClick={(e) => handleOpenMenu(e, setInventoryOption)}>
                    <Link to="#">Inventory</Link>
                  </MenuItem>
                  <Menu
                    anchorEl={inventoryOption}
                    open={Boolean(inventoryOption)}
                    onClose={() => handleCloseMenu(setInventoryOption)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "200px" || "auto",
                      },
                    }}
                  >
                    {userPermissionMenu.some((menu) =>
                      menu.menuName.includes("Transactions")
                    ) && (
                        <MenuItem
                          onClick={() =>
                            handleMenuItemClick(setReportsOption, setInventoryOption)
                          }
                        >
                          <Link to="/invnTrans">Inventory Transactions</Link>
                        </MenuItem>
                      )}
                  </Menu>
                </div>
              )}

            {userPermissionMenu.some((menu) =>
              menu.menuName.includes("Billing")
            ) && (
                <div>
                  <MenuItem onClick={(e) => handleOpenMenu(e, setBillingOption)}>
                    <Link to="#">Billing</Link>
                  </MenuItem>
                  <Menu
                    anchorEl={billingOption}
                    open={Boolean(billingOption)}
                    onClose={() => handleCloseMenu(setBillingOption)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: menuWidth || "auto",
                      },
                    }}
                  >
                    {userPermissionMenu.some((menu) =>
                      menu.menuName.includes("Invoice")
                    ) && (
                        <MenuItem
                          onClick={() =>
                            handleMenuItemClick(setReportsOption, setBillingOption)
                          }
                        >
                          <Link to="/reportInvoiceDetails">Invoice</Link>
                        </MenuItem>
                      )}
                  </Menu>
                </div>
              )}

            {userPermissionMenu.some((menu) =>
              menu.menuName.includes("Container")
            ) && (
                <div>
                  <MenuItem onClick={(e) => handleOpenMenu(e, setContainerOption)}>
                    <Link to="#">Container</Link>
                  </MenuItem>
                  <Menu
                    anchorEl={containerOption}
                    open={Boolean(containerOption)}
                    onClose={() => handleCloseMenu(setContainerOption)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "200px" || "auto",
                      },
                    }}
                  >
                    {userPermissionMenu.some((menu) =>
                      menu.menuName.includes("Container Details")
                    ) && (
                        <MenuItem
                          onClick={() =>
                            handleMenuItemClick(setReportsOption, setContainerOption)
                          }
                        >
                          <Link to="/reportContainerDetails">Container Details</Link>
                        </MenuItem>
                      )}
                  </Menu>
                </div>
              )}
          </Menu>

          { //Admin tab start
            (userPermissionMenu?.find((obj: any) =>
              userPermissionMenu?.some((charList: any) => charList.menuName.includes("Admin"))
            ) !== undefined) && (
              <Button
                className="hover-effect"
                onClick={(e) => handleOpenMenu(e, setAdminOption)}
              >
                <AdminPanelSettingsIcon /> &nbsp; Admin
              </Button>
            )}
          <Menu
            anchorEl={adminOption}
            open={Boolean(adminOption)}
            onClose={() => handleCloseMenu(setAdminOption)}
            PaperProps={{
              style: {
                width: "100px" || "auto",
              },
            }}
          >
            {userPermissionMenu.some((menu) =>
              menu.menuName.includes("App Settings")
            ) && (
                <MenuItem onClick={() => handleCloseMenu(setAdminOption)}>
                  <Link to="/userTabs">App Settings</Link>
                </MenuItem>
              )}
          </Menu>
          {userPermissionMenu.some((menu) =>
            menu.menuName.includes("App Settings")
          ) && (
              <Button
                className="hover-effect"
                component={Link}
                to="/reportBillOfLanding"
              >
                <DescriptionIcon /> &nbsp; BOL
              </Button>)}
        </div>)}
      {currentUserID && (
        <><div className="select-container">
          <div className="hover-effect">
            <TextOnlyDropdown
              options={userMasterData.map((s: any, index: any) => {
                return (
                  <MenuItem id={index} key={s.custID}
                    defaultValue={selectedUserID}
                    //  defaultChecked = {selectedUserID === s.custID}
                     selected={selectedUserID === s.custID}
                    onClick={() => handleUserChange(s.custID, s.customerName)}
                    value={s.custID}>
                    {s.customerName}
                  </MenuItem>
                );
              })}
              placeholder={selectedOwnerName} />
          </div>
          <div className="hover-effect">
            <TextOnlyDropdown
              options={userWhsMasterData.map((s: any, index: any) => {
                return (
                  <MenuItem id={index}
                    defaultValue={selectedWhsID}
                     selected={selectedWhsID === s.whseID}
                    value={s.whseID}
                    onClick={() => handleWhsChange(s.whseID, s.warehouseName)}
                  >{s.warehouseName}

                  </MenuItem>
                );
              })}
              placeholder={selectedWhsName} />
          </div>
        </div><div
          className="avatar-container avatar-border"
          onClick={(e) => handleOpenMenu(e, setAvatarOption)}
        >
            {window.innerWidth > 450 ? (
              <>
                <Avatar className="avatar" src="/broken-image.jpg" /> &nbsp;
              </>
            ) : (
              <span style={{ color: "#FFFFFF", margin: "4px" }}>My Account</span>
            )}
            <KeyboardArrowDownIcon style={{ color: "white" }} />
          </div></>
      )}
      <Menu
        anchorEl={avatarOption}
        open={Boolean(avatarOption)}
        onClose={() => handleCloseMenu(setAvatarOption)}
        PaperProps={{
          style: {
            width: 100,
          },
        }}
      >
        {/* <MenuItem onClick={() => handleCloseMenu(setAvatarOption)}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => handleCloseMenu(setAvatarOption)}>
          Settings
        </MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default Topnav;
