import React from "react"
import { useNavigate } from 'react-router-dom';



export default function NoDashboard() {
    const navigate = useNavigate();

   


    React.useEffect(() => {
        console.log("No dashboard effect",localStorage)
        navigate("/dashboard")
        
        const dashboardTab = localStorage.getItem("isDashboard")
        if(dashboardTab == "true")
            {
              console.log("no dashboard loaded")
              // window.location.reload()
             // navigate("/dashboard")
               //localStorage.setItem("isDashboard", "false");
            }
       // window.location.reload();
        // const dashboardTab = localStorage.getItem("isDashboard")
        // debugger
        // console.log("no dash",dashboardTab)
        // if(dashboardTab)
        //     {
        //        // navigate("/dashboard")
        //     }
        //     else{               
        //        // window.location.reload();
        //       //  localStorage.setItem("isDashboard", "true");
        //     }
       
    },[])
    return (

        <div className='ml-2' style={{ height: 500, width: '80%' }}>
            <div className='mt-8 mb-6 text-center pageHeading'> </div>
            <div className='pb-2' style={{ width: '30%' }}>

            </div>
            <p className='ml-2 mb-2'>
                <h6 className='colordivSumHead'> No Dashboard access</h6>
            </p>
        </div>

    )
}